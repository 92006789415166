import styled, { css } from 'styled-components';
import { UpcomingCard } from '~/components/Cards';
import { useCms } from '~/store/cmsContext/hooks';

export interface BannerFeedProps {}

const BannerFeed = ({}: BannerFeedProps) => {
  const { cms } = useCms();
  const banners = cms?.banners?.home?.upcoming?.filter((banner) => banner.display.show);

  return (
    <Root>
      {banners?.map((banner, index) => (
        <UpcomingCard key={index} banner={banner} />
      ))}
    </Root>
  );
};

export default BannerFeed;

/** Styled components */

const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 16px;
    & > * {
      width: 100%;
      grid-column: span 12;
    }
    @media (min-width: 600px) {
      grid-gap: 24px;
    }
    @media (min-width: 960px) {
      grid-gap: 32px;
      & > * {
        grid-column: span 4;
      }
    }
  `}
`;
